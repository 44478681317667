<template>
  <v-container>
    <v-form v-model="valid">
      <v-card outlined hover loading color="#ff00ff">
        <v-card-title class="estimate-title">
          <v-row>
            <v-spacer></v-spacer>
            <div>Request Your Free Estimate</div>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-title>
        <v-card-text class="estimate-body">
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                dense
                label="Name"
                v-model="estimate.name"
                required
                outlined
                filled
                :rules="rulesName"
                hint="Name"
              />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                dense
                label="Address"
                v-model="estimate.address"
                outlined
                filled
                hint="Address"
              />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                dense
                label="City"
                v-model="estimate.city"
                outlined
                filled
                hint="City"
              />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                dense
                label="Zipcode"
                v-model="estimate.zipcode"
                outlined
                filled
                hint="Zipcode"
              />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                dense
                label="Email"
                prepend-icon="mdi-at"
                v-model="estimate.email"
                outlined
              />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                dense
                prepend-icon="mdi-card-account-phone"
                label="Phone"
                v-model="estimate.phone"
                outlined
              />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-select
                v-model="selectedProjects"
                :items="projects"
                attach
                chips
                dense
                deletable-chips
                label="Project Type"
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-textarea
                dense
                label="Message"
                v-model="estimate.message"
                outlined
              />
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-text-field
                dense
                prepend-icon="mdi-lock"
                :label="verifyLabel"
                v-model="answer"
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="estimate-title">
          <v-spacer></v-spacer>
          <v-btn outlined @click="onSubmit"
            ><v-icon>mdi-save</v-icon>Submit</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
    <div>
      <status
        ref="alert"
        successMessage="Estimate saved"
        @closed="onStatusAlertClosed"
      ></status>
    </div>
  </v-container>
</template>

<script>
import EstimateService from "@/_services/api/estimate-service"
import status from "../../components/controls/status-ctrl"

export default {
  name: "estimate-card",

  components: {
    status,
  },

  created() {
    this.verify[0] = Math.floor(Math.random() * 3) + 1;
    this.verify[1] = Math.floor(Math.random() * 3) + 1;
    this.verifyLabel = this.verifyLabel =
      "Verify " + this.verify[0] + " + " + this.verify[1];
  },

  data: () => ({
    answer: "",
    estimate: {
      id: 0,
      name: "",
      address: "",
      city: "",
      estimateDate: new Date(),
      zipcode: "",
      message: "",
      email: "",
      phone: "",
      projectType: "",
    },
    projects: ["Roof", "Gutter", "Siding", "Windows"],
    rulesName: [
      (v) => !!v || "Name is required",
      (v) => (v || "").length <= 255 || "Name must be 255 characters or less",
    ],
    selectedProjects: [],
    valid: false,
    verify: [0, 0],
    verifyLabel: "",
    waterImage: require("@/assets/rain-3.jpg"),
  }),

  methods: {
    onSubmit() {
      const total = this.verify[0] + this.verify[1];
      const result = {
        statusCode: 200,
        errors: [],
      };
      if (this.estimate.name.length == 0) {
        (result.statusCode = 400), result.errors.push("Name is required");
      }
      if (this.estimate.email.length == 0 && this.estimate.phone.length == 0) {
        (result.statusCode = 400),
          result.errors.push("Email or Phone is required");
      }
      if (this.answer != total) {
        result.statusCode = 400;
        result.errors.push("Verify total does not add up");
      }
      if (result.statusCode == 200) {
        this.selectedProjects.forEach((type) => {
          if (this.estimate.projectType.length > 0) {
            this.estimate.projectType += ", ";
          }
          this.estimate.projectType += type;
        });
        console.log('Estimate: ', this.estimate)
        result.errors.push("Saving your request")
        this.$refs.alert.displayResult(result);
        EstimateService.save(this.estimate).then(() => {
          result.errors = []
          result.errors.push("Estimate saved")
          this.$refs.alert.displayResult(result);
        });
      } else {
        result.errors.push(" ");
        this.$refs.alert.displayResult(result);
      }
    },

    onStatusAlertClosed() {},
  },
};
</script>

<style lang="scss" scoped>
.estimate-title {
  background-color:#4d004d;
  color: white;
}
.theme--light.v-card > .v-card__text {
  background: linear-gradient(
    to bottom,
    $light-gradient-low-color 10%,
    $light-gradient-high-color 100%
  );
}
.theme--dark.v-card > .v-card__text {
  background: linear-gradient(
    to bottom,
    $dark-gradient-low-color 10%,
    $dark-gradient-high-color 100%
  );
}

.estimate {
  background: linear-gradient(to bottom, #ffccff 0%, #e600e6 100%);
}
</style>
