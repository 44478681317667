import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from '@/_utility/role'
import store from '@/_store/index'

Vue.use(VueRouter)
const commonTags = [
  {
    name: 'viewport',
    content: 'width=device-width,minimum-scale=1,maximum-scale=1',
  },
  {
    name: 'fragment',
    content: '!',
  },
  {
    name: 'robots',
    content: 'index, follow',
  },
  {
    name: 'googlebot',
    content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
  },
  {
    name: 'bingbot',
    content: 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
  },
  {
    name: 'charset',
    content: 'UTF-8',
  },
  {
    property: 'og:local',
    content: 'en_US',
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:url',
    content: 'http://www.aquaductroofing.com/',
  },
  {
    property: 'og:site_name',
    content: 'AquaDuct Roof and Gutters',
  },
  {
    property: 'article:modified_time',
    content: '2021-01-26T00:00+00.00',
  },
  {
    name: 'twitter:card',
    content: 'summary',
  },
  {
    name: 'google-site-verification',
    content: 'K3RgKjA18sPhdoTN5DU4TsX0vXJVDhBKrgjkUOX8fJU',
  },
]
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { 
      authorize: [],
      title: 'Home - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Home - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: 'Welcome to AquaDuct Roof and Gutters, now celebrating four decades of service to homeowners in Denver, Colorado Springs, Pueblo and surrounding communities. Whether you need roofing in Colorado Springs, roof repair, seamless gutters in Denver, replacement windows or siding, you can trust our local roofing contractors to complete the work […]',
        },
      ],
    },
    component: () =>
      import('../views/home-page.vue')
  },
  {
    path: '/why',
    name: 'Why',
    meta: { 
      authorize: [],
      title: 'Why Choose Aqua Duct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Why - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    component: () =>
      import('../views/why-page.vue')
  },
  {
    path: '/roofing/home',
    name: 'Roofing',
    meta: { 
      authorize: [],
      title: 'Roofing - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Roofing - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: 'AquaDuct Roof and Gutters is one of the most experienced Colorado Springs and Denver roofing contractors. We’ve been replacing and repairing roofing in Colorado Springs for over 40 years, giving our roofers the expertise necessary to handle any type of residential application. When you contact AquaDuct for assistance with your roofing project, count on our family-owned business to provide the kind of personal attention and care you can’t get from one of the big Denver roofing companies.',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/roofing/roofing-home.vue')
  },
  {
    path: '/roofing/repair',
    name: 'Roofing Repair',
    meta: { 
      authorize: [],
      title: 'Roofing Repair - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Roofing Repair - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: 'AquaDuct Roof and Gutters offers prompt, professional repair of roofing in Colorado Springs, CO, and throughout metro Denver. Whether you have wind-damaged shingles, hail damage that has taken its toll, or leaks due to ice damming, we’re here to help.',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/roofing/roofing-repair.vue')
  },
  {
    path: '/roofing/replace',
    name: 'Roofing Replace',
    meta: { 
      authorize: [],
      title: 'Roofing Replace - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Roofing Replace - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: 'In general, most Colorado Springs roofing needs to be replaced every ten to 25 years due to normal wear and tear. However, some situations demand early replacement: building code changes, damage caused by severe weather, manufacturer defects, and so on. Occasionally roofing in Colorado Springs will leak due to some level of improper installation.',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/roofing/roofing-replace.vue')
  },
  {
    path: '/roofing/ice',
    name: 'Roofing Ice Dams',
    meta: { 
      authorize: [],
      title: 'Roofing Ice Dams - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Roofing Ice Dams - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/roofing/ice-dams.vue')
  },
  {
    path: '/roofing/signs',
    name: 'Roofing Signs',
    meta: { 
      authorize: [],
      title: 'Roofing Signs - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Roofing Signs - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/roofing/roofing-signs.vue')
  },
  {
    path: '/roofing/choose',
    name: 'Roofing Choosing',
    meta: { 
      authorize: [],
      title: 'Roofing Choosing - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Roofing Choosing - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/roofing/roofing-choose.vue')
  },
  {
    path: '/roofing/materials',
    name: 'Roofing Materials FAQ',
    meta: { 
      authorize: [],
      title: 'Roofing Materials FAQ - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Roofing Materials FAQ - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/roofing/roofing-materials.vue')
  },
  {
    path: '/gutters',
    name: 'Gutters',
    meta: { 
      authorize: [],
      title: 'Gutters - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Gutters - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/gutters/gutters-home.vue')
  },
  {
    path: '/gutters/repair',
    name: 'Gutter Repair',
    meta: { 
      authorize: [],
      title: 'Gutter Repair - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Gutter Repair - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/gutters/gutters-repair.vue')
  },
  {
    path: '/gutters/replace',
    name: 'Gutter Replace',
    meta: { 
      authorize: [],
      title: 'Gutter Replace - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Gutter Replace - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/gutters/gutters-replace.vue')
  },
  {
    path: '/gutters/protection',
    name: 'Gutter Protection',
    meta: { 
      authorize: [],
      title: 'Gutter Protection - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Gutter Protection - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/gutters/gutters-protection.vue')
  },
  {
    path: '/windows/home',
    name: 'Windows',
    meta: { 
      authorize: [],
      title: 'Windows - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Windows - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/windows/window-home.vue')
  },
  {
    path: '/windows/energy',
    name: 'Window Energy',
    meta: { 
      authorize: [],
      title: 'Windows Energy - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Windows Energy - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/windows/window-energy.vue')
  },
  {
    path: '/windows/why',
    name: 'Window Why',
    meta: { 
      authorize: [],
      title: 'Windows Why - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Windows Why - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/windows/window-replace.vue')
  },
  {
    path: '/siding/home',
    name: 'Siding',
    meta: { 
      authorize: [],
      title: 'Siding - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Siding - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/siding/siding-home.vue')
  },
  {
    path: '/siding/options',
    name: 'Siding Options',
    meta: { 
      authorize: [],
      title: 'Siding Options - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Siding Options - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/siding/siding-options.vue')
  },
  {
    path: '/siding/trim',
    name: 'Siding Trim Packages',
    meta: { 
      authorize: [],
      title: 'Siding Trim Packages - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Siding Trim Packages - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/siding/trim-packages.vue')
  },
  {
    path: '/photo',
    name: 'Photos',
    meta: { 
      authorize: [],
      title: 'Photos - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Photos - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/photo/photo-gallery.vue')
  },
  {
    path: '/photo/edit/:id',
    name: 'Edit Photo',
    excludeFromSiteMap: true,
    meta: { 
      authorize: [Role.Admin, Role.Manager, Role.Office, Role.Foreman, Role.Blogger],
      title: 'Edit Photo - AquaDuct Roof and Gutters',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/photo/photo-edit.vue')
  },
  {
    path: '/about/home',
    name: 'About Us',
    meta: { 
      authorize: [],
      title: 'About Us - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'About Us - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/about/about-home.vue')
  },
  {
    path: '/about/map',
    name: 'Map',
    meta: { 
      authorize: [],
      title: 'Map - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Map - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/about/about-map.vue')
  },
  {
    path: '/about/review',
    name: 'Review',
    meta: { 
      authorize: [],
      title: 'Review - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Review - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/about/about-review.vue')
  },
  {
    path: '/about/insurance',
    name: 'Insurance',
    meta: { 
      authorize: [],
      title: 'Insurance - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Insurance - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/about/about-insurance.vue')
  },
  {
    path: '/about/staff',
    name: 'About Staff',
    meta: { 
      authorize: [],
      title: 'Staff - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Staff - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/about/about-staff.vue')
  },
  {
    path: '/about/projects',
    name: 'Recent Projects',
    meta: { 
      authorize: [],
      title: 'Recent Projects - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Recent Projects - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/about/about-projects.vue')
  },
  {
    path: '/contact',
    name: 'Contact Us',
    meta: { 
      authorize: [],
      title: 'Contact Us - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Contact Us - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/contact-page.vue')
  },
  // {
  //   path: '/search',
  //   name: 'Search',
  //   meta: { 
  //     authorize: [],
  //     title: 'Search - AquaDuct Roof and Gutters',
  //     metaTags: [
  //       {
  //         property: 'og:title',
  //         content: 'Search - AquaDuct Roof and Gutters',
  //       },
  //       {
  //         property: 'og:description',
  //         content: '',
  //       },
  //     ],
  //   },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import('../views/Search.vue')
  // },
  {
    path: '/blog',
    name: 'Blog',
    meta: { 
      authorize: [],
      title: 'Blog - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Blog - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/blogs/blog-list.vue')
  },
  {
    path: '/blog/:id',
    name: 'View Blog',
    meta: { 
      authorize: [],
      title: 'View Blog - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'View Blog - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/blogs/blog-view.vue')
  },
  {
    path: '/blog/edit/:id',
    name: 'Add-Edit Blog',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Blogger, Role.Manager] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/blogs/blog-edit.vue')
  },
  {
    path: '/location/*',
    name: 'Location',
    excludeFromSiteMap: true,
    meta: { authorize: [] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/static-page.vue')
  },
  // {
  //   path: '/location/castlerock',
  //   name: 'Castle Rock',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import('../views/Location/CastleRock.vue')
  // },
  // {
  //   path: '/location/coloradosprings',
  //   name: 'Colorado Springs',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import('../views/Location/ColoradoSprings.vue')
  // },
  // {
  //   path: '/location/Pueblo',
  //   name: 'Pueblo',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import('../views/Location/Pueblo.vue')
  // },
  // {
  //   path: '/location/Peyton',
  //   name: 'Peyton',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import('../views/Location/Peyton.vue')
  // },
  {
    path: '/account/forgot',
    name: 'Forgot Password',
    excludeFromSiteMap: true,
    meta: { 
      authorize: [],
      title: 'Forgot Password - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Forgot Password - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/account/account-forgot.vue')
  },
  {
    path: '/account/login',
    name: 'Login',
    excludeFromSiteMap: true,
    meta: { 
      authorize: [],
      title: 'Login - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Login - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/account/account-login.vue')
  },
  {
    path: '/account/register',
    name: 'Register',
    excludeFromSiteMap: true,
    meta: { 
      authorize: [],
      title: 'Register - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Register - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/account/account-register.vue')
  },
  {
    path: '/account/reset',
    name: 'Reset Password',
    excludeFromSiteMap: true,
    meta: { 
      authorize: [],
      title: 'Reset Password - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Reset Password - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/account/account-reset.vue')
  },
  {
    path: '/account/logout',
    name: 'Logout',
    excludeFromSiteMap: true,
    meta: { 
      authorize: [],
      title: 'Logout - AquaDuct Roof and Gutters',
      metaTags: [
        {
          property: 'og:title',
          content: 'Logout - AquaDuct Roof and Gutters',
        },
        {
          property: 'og:description',
          content: '',
        },
      ],
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/account/account-logout.vue')
  },
  {
    path: '/account/profile',
    name: 'Profile',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Blogger, Role.Manager, Role.Office, Role.Foreman, Role.User] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/account/account-profile.vue')
  },
  {
    path: '/setting/pages',
    name: 'Pages',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Manager] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/settings/page-list.vue')
  },
  {
    path: '/setting/page/:id',
    name: 'Page',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Manager] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/settings/page-edit.vue')
  },
  {
    path: '/setting/section/:pageId/:id',
    name: 'Section',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Manager] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/settings/page-section.vue')
  },
  {
    path: '/setting/estimate',
    name: 'Estimates',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Manager, Role.Office] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/settings/estimate-list.vue')
  },
  {
    path: '/setting/estimate/:id',
    name: 'Edit Estimate',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Manager, Role.Office] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/settings/estimate-edit.vue')
  },
  {
    path: '/setting/log',
    name: 'Logs',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/settings/log-list.vue')
  },
  {
    path: '/setting/log/detail/:id',
    name: 'Log Detail',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/settings/log-detail.vue')
  },
  {
    path: '/setting/userroles',
    name: 'User Roles',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Manager] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/settings/user-roles.vue')
  },
  {
    path: '/unauth',
    name: 'Unauthorized',
    excludeFromSiteMap: true,
    meta: { authorize: [Role.Admin, Role.Manager, Role.Blogger, Role.Office, Role.Foreman, Role.User] },
    component: () =>
      import('../unauthorized-view.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(nearestWithMeta) {
    const tags = nearestWithMeta.meta.metaTags.concat(commonTags)
    
    // Turn the meta tag definitions into actual elements in the head.
    tags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));
  }

  const { authorize } = to.meta
  const currentUser = store.getters.getUser
  if(authorize && authorize.length > 0) {
    if(!currentUser.loggedIn) {
      return next({path: '/account/login', query: { returnUrl: to.path }})
    }

    if(authorize.length && !authorize.includes(currentUser.role)) {
      return next({ path: '/unauth' })
    }
  }

  next()
})
export default router
