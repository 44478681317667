export default {
  getMenu: function() {
    return [
      {
        id: "home",
        icon: "mdi-home",
        breakpoint: 6,
        rule: "",
        text: "Home",
        subMenuItems: [],
        to: "/",
      },
      {
        id: "roof",
        icon: "mdi-home-roof",
        breakpoint: 2,
        text: "Roofing",
        subMenuItems: [
          {
            id: "roof.home",
            icon: "mdi-home-roof",
            text: "Home",
            subMenuItems: [],
            to: "/roofing/home",
          },
          {
            id: "roof.replace",
            icon: "mdi-file-replace-outline",
            text: "Replacement",
            subMenuItems: [],
            to: "/roofing/replace",
          },
          {
            id: "roof.repair",
            icon: "mdi-auto-fix",
            text: "Repairs",
            subMenuItems: [],
            to: "/roofing/repair",
          },
          {
            id: "roof.dam",
            icon: "mdi-water-alert",
            text: "Ice Dams",
            subMenuItems: [],
            to: "/roofing/ice",
          },
          {
            id: "roof.sign",
            icon: "mdi-sign-caution",
            text: "Signs",
            subMenuItems: [],
            to: "/roofing/signs",
          },
          {
            id: "roof.choose",
            icon: "mdi-checkbox-marked-outline",
            text: "Choose",
            subMenuItems: [],
            to: "/roofing/choose",
          },
          {
            id: "roof.faq",
            icon: "mdi-frequently-asked-questions",
            text: "Materials FAQ",
            subMenuItems: [],
            to: "/roofing/materials",
          },
        ],
        to: "/roofing",
      },
      {
        id: "gutters",
        icon: "mdi-water-outline",
        breakpoint: 2,
        rule: "",
        text: "Gutters",
        subMenuItems: [
          {
            id: "gutters.home",
            icon: "mdi-water-outline",
            text: "Home",
            subMenuItems: [],
            to: "/gutters",
          },
          {
            id: "gutters.repair",
            icon: "mdi-auto-fix",
            text: "Repair",
            subMenuItems: [],
            to: "/gutters/repair",
          },
          {
            id: "gutters.replace",
            icon: "mdi-file-replace-outline",
            text: "Replace",
            subMenuItems: [],
            to: "/gutters/replace",
          },
          {
            id: "gutters.protection",
            icon: "mdi-shield-sun-outline",
            text: "Protection",
            subMenuItems: [],
            to: "/gutters/protection",
          },
        ],
        to: "/gutters",
      },
      {
        id: "windows",
        icon: "mdi-window-closed-variant",
        breakpoint: 3,
        rule: "",
        text: "Windows",
        subMenuItems: [
          {
            id: "windows.home",
            icon: "mdi-window-closed",
            text: "Home",
            subMenuItems: [],
            to: "/windows/home",
          },
          {
            id: "windows.enegry",
            icon: "mdi-frequently-asked-questions",
            text: "Energy Saving FAQ",
            subMenuItems: [],
            to: "/windows/energy",
          },
          {
            id: "window.replace",
            icon: "mdi-file-replace-outline",
            text: "Why Replace",
            subMenuItems: [],
            to: "/windows/why",
          },
        ],
        to: "/",
      },
      {
        id: "siding",
        icon: "mdi-shield-home-outline",
        breakpoint: 3,
        rule: "",
        text: "Siding",
        subMenuItems: [
          {
            id: "siding.home",
            icon: "mdi-shield-home-outline",
            text: "Siding",
            subMenuItems: [],
            to: "/siding/home",
          },
          {
            id: "siding.options",
            icon: "mdi-form-select",
            text: "Options",
            subMenuItems: [],
            to: "/siding/options",
          },
          {
            id: "siding.trim",
            icon: "mdi-package-variant-closed",
            text: "Trim Packages",
            subMenuItems: [],
            to: "/siding/trim",
          },
        ],
        to: "/",
      },
      {
        id: "projects",
        icon: "mdi-image",
        breakpoint: 4,
        rule: "",
        text: "Recent Projects",
        subMenuItems: [],
        to: "/about/projects",
      },
      {
        id: "about",
        icon: "mdi-information-outline",
        breakpoint: 4,
        rule: "",
        text: "About Us",
        subMenuItems: [
          {
            id: "about.home",
            icon: "mdi-information-variant",
            text: "About",
            subMenuItems: [],
            to: "/about/home",
          },
          {
            id: "about.staff",
            icon: "mdi-account-group-outline",
            text: "Staff",
            subMenuItems: [],
            to: "/about/staff",
          },
          {
            id: "about.review",
            icon: "mdi-message-draw",
            text: "Reviews",
            subMenuItems: [],
            to: "/about/review",
          },
          {
            id: "about.map",
            icon: "mdi-map",
            text: "Map",
            subMenuItems: [],
            to: "/about/map",
          },
          {
            id: "about.license",
            icon: "mdi-shield-car",
            text: "License & Insurance",
            subMenuItems: [],
            to: "/about/insurance",
          },
        ],
        to: "/about/home",
      },
      {
        id: "contact",
        icon: "mdi-email",
        breakpoint: 2,
        rule: "",
        text: "Contact Us",
        subMenuItems: [],
        to: "/contact",
      },
      // {
      //   id: "search",
      //   icon: "mdi-magnify",
      //   breakpoint: 5,
      //   rule: "",
      //   text: "Search",
      //   subMenuItems: [],
      //   to: "/search",
      // },
      // {
      //   id: "blog",
      //   icon: "mdi-post",
      //   breakpoint: 2,
      //   text: "Blog",
      //   rule: "",
      //   subMenuItems: [],
      //   to: "/blog",
      // },
      {
        id: "location",
        icon: "mdi-map-marker",
        breakpoint: 5,
        rule: "",
        text: "Locations",
        subMenuItems: [
          {
            id: "location.denver",
            icon: "mdi-map-marker",
            text: "Denver",
            subMenuItems: [],
            to: "/location/denver",
          },
          {
            id: "location.coloradosprings",
            icon: "mdi-map-marker",
            text: "Colorado Springs",
            subMenuItems: [],
            to: "/location/coloradosprings",
          },
          {
            id: "location.pueblo",
            icon: "mdi-map-marker",
            text: "Pueblo",
            subMenuItems: [],
            to: "/location/pueblo",
          },
          {
            id: "location.peyton",
            icon: "mdi-map-marker",
            text: "Peyton",
            subMenuItems: [],
            to: "/location/peyton",
          },
        ],
        to: "/",
      },
      {
        id: "account",
        breakpoint: 5,
        icon: "mdi-account-outline",
        text: "Account",
        subMenuItems: [
          {
            id: "account.login",
            icon: "mdi-login",
            loggedIn: false,
            text: "Login",
            subMenuItems: [],
            to: "/account/login",
            rule: "loggedOut",
          },
          {
            id: "account.register",
            icon: "mdi-account-plus-outline",
            loggedIn: false,
            text: "Register",
            subMenuItems: [],
            to: "/account/register",
            rule: "loggedOut",
          },
          {
            id: "account.reset",
            icon: "mdi-lock-reset",
            loggedIn: false,
            text: "Reset Password",
            subMenuItems: [],
            to: "/account/reset",
            rule: "loggedOut",
          },
          {
            id: "account.forgot",
            icon: "mdi-account-question-outline",
            loggedIn: false,
            text: "Forgot Username",
            subMenuItems: [],
            to: "/account/forgot",
            rule: "loggedOut",
          },
          {
            id: "account.logout",
            icon: "mdi-logout",
            loggedIn: true,
            text: "Logout",
            subMenuItems: [],
            to: "/account/logout",
            rule: "loggedIn",
          },
          {
            id: "account.profile",
            icon: "mdi-account-cog-outline",
            loggedIn: true,
            text: "Profile",
            subMenuItems: [],
            to: "/account/profile",
            rule: "loggedIn",
          },
        ],
        to: "/",
      },
      {
        id: "setting",
        breakpoint: 5,
        icon: "mdi-cog",
        text: "Settings",
        to: "/",
        rule: "manager",
        subMenuItems: [
          {
            id: "setting.estimates",
            icon: "mdi-view-list",
            loggedIn: true,
            text: "Estimates",
            subMenuItems: [],
            to: "/setting/estimate",
            rule: "manager",
          },
          {
            id: "setting.logs",
            icon: "mdi-view-list",
            loggedIn: true,
            text: "Logs",
            subMenuItems: [],
            to: "/setting/log",
            rule: "administrator",
          },
          {
            id: "setting.pages",
            icon: "mdi-book-open-page-variant-outline",
            loggedIn: true,
            text: "Pages",
            subMenuItems: [],
            to: "/setting/pages",
            rule: "administrator",
          },
          {
            id: "setting.userroles",
            icon: "mdi-account-cog",
            loggedIn: true,
            text: "User Roles",
            subMenuItems: [],
            to: "/setting/userroles",
            rule: "manager",
          },
        ]
      },
    ];
  },
};
