import axios from "axios";
import store from "@/_store/index";
import API from "./index";
export default {
  list() {
    console.log(API.x)
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Pages";
    return axios.get(url, {})
    .then((response) => {
      return response.data;
    })
    .finally(() => {
        store.commit("notLoading");
    })
  },

  get(path) {
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Pages";
    return axios.get(url, {})
    .then((response) => {
      let page = null
      for(var i = 0; i < response.data.length; i++) {
        const temp = response.data[i]
        if(temp.path == path) {
          page = temp;
          break;
        }
      }
      return page;
    })
    .finally(() => {
        store.commit("notLoading");
    })
  },

  getId(id) {
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Pages/" + id;
    return axios.get(url, {})
    .then((response) => {
      return response.data;
    })
    .finally(() => {
        store.commit("notLoading");
    })
  },

  save(page) {
    if(page.id > 0) {
      return this.update(page)
    } else {
      return this.create(page)
    } 
  },

  create(page) {
    const url = process.env.VUE_APP_BASE_URL + "Pages"
    return axios.post(url, page)
      .then(result => {
        return result
      })
  },

  update(page) {
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Pages/" + page.id
    return axios.put(url, page)
      .then(result => {
        return result
      })
      .catch(error => { 
        return error
      })
      .finally(() => {
        store.commit("notLoading");
      })
  },
  
  delete(id) {
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Pages/" + id
    return axios.delete(url, {})
      .then(result => {
        return result
      })
      .catch(error => { 
        return error
      })
      .finally(() => {
        store.commit("notLoading");
      })
  }
};
